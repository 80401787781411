import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Nav from '../navbar';
function Buyleads() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [lead, setLead] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    
   
    Expired()
   
    if (v === null) {
    } else {
      setUid(v[0].id);
    }
  }, []);



 
  const Expired = async () => {
    const data = new FormData();
   
    data.append("val", "inactive");
   
    axios({
      method: "post",
      url: "/apis/subscription/manage_subs.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Active Packages",  s.data);
        setList(s.data)
        // navigate("/maincat");
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

 

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3> Expired Subscription Holder</h3>
                  </div>
                  <hr/>
                {/* <div className="card-title">
                    <h4>Search Filters </h4>
                  </div>
                <div style={{flexDirection:"row"}} className='row '>

<div className='col-3'>

<select className='form-control' placeholder='Enter Categories Name' >
  <option>Company Name</option>
  <option>User Id</option>
  <option>Name</option>
  <option>Date</option>
  <option>Package Plan</option>
  </select> 
</div>
<div className='col-3'>

<input className='form-control' placeholder='Enter Categories Name' /> 
</div>
<div className='col-3'>

<button className='btn-primary my-1 px-3 py-1' > Search</button>
</div>

</div>
<hr/> */}
<br/>
                
                  <div className="card-body">
                    <div className="table-responsive">
                    <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>User Id</th>

                            <th>Name</th>
                            <th>Total Leads</th>
                            <th>Used Leads</th>
                            <th> Remaining Leads</th>
                            <th>Purchases At</th>
                            <th>Expired At</th>
                            
                          

                          </tr>
                        </thead>
                        <tbody>
                          {list.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              {list.map((v, i) => (
                                <tr>
                                  <td>{i+1}</td>
                                  <td>{v.uid}</td>
                                  <td>{v.name}</td>
                                  <td>{v.total}</td>
                                  <td>{v.used} </td>
                                  <td>{v.remaining}</td>
                                  <td>{v.time}</td>
                                  <td>{v.validity}</td>
                             
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
