import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Nav from '../navbar';
function Buyleads() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [lead, setLead] = useState([]);
  const [sold, setSold] = useState([]);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    lead_view_all();
    Sold_lead();
    if (v === null) {
    } else {
      setUid(v[0].id);
    }
  }, []);



  const lead_view_all = async () => {
    const data = new FormData();
    data.append('val',"active" );

    axios({
      method: 'post',
      url: '/apis/getquote/manage_quotes.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('lead list ', s.data);
        const a = s.data;
        setLead(a);
      })
      .catch(s => {
        console.log('here lead_view_all ', s);
      });
  };

  
  const Sold_lead = async () => {
    const data = new FormData();
    data.append('val',"sold" );

    axios({
      method: 'post',
      url: '/apis/getquote/manage_quotes.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('lead list ', s.data);
        const a = s.data;
        setSold(a);
      })
      .catch(s => {
        console.log('here lead_view_all ', s);
      });
  };

 
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3> General Buy Requests</h3>
                  </div>
                  <hr/>
                  {/* <div className="card-title">
                    <h4>Search Filters </h4>
                  </div>
                <div style={{flexDirection:"row"}} className='row '>


                    <div className='col-3'>

                    <input className='form-control' type='date' /> 
                    </div>
                    <div className='col-3'>

                    <input className='form-control' type='date' /> 
                    </div>
                    <div className='col-3'>

                    <button className='btn-primary my-1 px-3 py-1' > Search</button>
                    </div>

                </div>
                <hr/> */}
                
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>User Id</th>
                            <th>Lead Id</th>

                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Unit</th>
                            <th>Description</th>
                            <th>Time</th>
                            <th>Status</th>
                          

              
                          </tr>
                        </thead>
                        <tbody>
                          {lead.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              {lead.map((v, i) => (
                                <tr>
                                  <td>{v.id}</td>
                                  <td>{v.uid}</td>
                                  <td>{v.lead_id}</td>
                                  <td>{JSON.parse(v.sheet)[0].name} </td>
                                  <td>{JSON.parse(v.sheet)[0].qty} </td>
                                  <td>{JSON.parse(v.sheet)[0].price} </td>
                                  <td>{JSON.parse(v.sheet)[0].piece} </td>
                                  <td>{JSON.parse(v.sheet)[0].des} </td>
                                  <td>{v.time} </td>
                                  <td style={{color:"green"}}>
                                    {v.status==""?'active':'sold'} </td>
                                  {/* <td>
                                    <a
                                      href={v.file}
                                      target="_blank"
                                      download={v.lead_id}>
                                      Download{' '}
                                    </a>{' '}
                                  </td> */}
                                 

                                  
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <br/>

            {/* Sold Requests */}

            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3>  Sold Requests</h3>
                  </div>
                  <hr/>
                  {/* <div className="card-title">
                    <h4>Search Filters </h4>
                  </div>
                <div style={{flexDirection:"row"}} className='row '>


                    <div className='col-3'>

                    <input className='form-control' type='date' /> 
                    </div>
                    <div className='col-3'>

                    <input className='form-control' type='date' /> 
                    </div>
                    <div className='col-3'>

                    <button className='btn-primary my-1 px-3 py-1' > Search</button>
                    </div>

                </div>
                <hr/> */}
                
                <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>User Id</th>
                            <th>Lead Id</th>

                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Unit</th>
                            <th>Description</th>
                            <th>Time</th>
                            <th>Status</th>
                          

              
                          </tr>
                        </thead>
                        <tbody>
                          {sold.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              {sold.map((v, i) => (
                                <tr>
                                  <td>{v.id}</td>
                                  <td>{v.uid}</td>
                                  <td>{v.lead_id}</td>
                                  <td>{JSON.parse(v.sheet)[0].name} </td>
                                  <td>{JSON.parse(v.sheet)[0].qty} </td>
                                  <td>{JSON.parse(v.sheet)[0].price} </td>
                                  <td>{JSON.parse(v.sheet)[0].piece} </td>
                                  <td>{JSON.parse(v.sheet)[0].des} </td>
                                  <td>{v.time} </td>
                                  <td style={{color:"green"}}>
                                    {v.status==""?'active':'sold'} </td>
                                  {/* <td>
                                    <a
                                      href={v.file}
                                      target="_blank"
                                      download={v.lead_id}>
                                      Download{' '}
                                    </a>{' '}
                                  </td> */}
                                 

                                  
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
