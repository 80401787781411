import { BrowserRouter, Routes, Route } from "react-router-dom";

// import Login from "./pages/login";

import Forgotten from "./pages/forget";
// Seller Side
// import Login from "./pages/login";
import User from "./Master/user";
import Driver from "./Master/driver";
import Packagehistory from "./Master/subscription/Packagehistory";
// import ProductList from "./Master/product/products";
import Dashboard from "./Master/Dashboard";
import UserList from "./Master/Reports/UserList";
import Login from "./Master/Login";

// Categories
import MainCategories from "./Master/Cat/Maincat";
import Subcat from "./Master/Cat/Subcat";
import Macrocat from "./Master/Cat/Macrocat";
import Updatecat from "./Master/Cat/Updatecat";


// GetQuotes
import SignupReport from "./Master/Reports/SignupReport";
import DailyQuote from "./Master/Reports/GetQuotesReport";
import Promotion from "./Master/Reports/Promotion";
import Mattersheetlist from "./Master/Reports/Mattersheetlist";


// Packages report
import Packages from "./Master/subscription/Packages";
import Expired from "./Master/subscription/Expired";
// E-wallet
import Balance from "./Master/useracess/wallet/balance";

// Packages user
import Packagess from "./Master/useracess/subscription/Packages";
import Subscription from "./Master/useracess/subscription/Subscription";
import BuyPackageNow from "./Master/useracess/subscription/buypackage";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/userlist" element={<UserList />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/packagehistory" element={<Packagehistory />} />
          <Route path="/login" element={<Login />} />

          {/* <Route path="/master" element={<Dashboard />} /> */}
          
          <Route path="/promotion" element={<Promotion />} />
          <Route path="/mattersheetlist" element={<Mattersheetlist />} />
          

          {/* Packages */}
          <Route path="/packages" element={<Packages />} />
          <Route path="/expired" element={<Expired />} />


          {/* Get Quotes */}
          <Route path="/dailyquote" element={<DailyQuote />} />
          <Route path="/signupReport" element={<SignupReport />} />

        {/* Categories */}
          <Route path="/maincat" element={<MainCategories />} />
          <Route path="/subcat" element={<Subcat />} />
          <Route path="/macrocat" element={<Macrocat />} />
          <Route path="/updatecat" element={<Updatecat />} />
          

          {/* Balance */}
          <Route path="/balance" element={<Balance />} />

           {/* Packages */}
           <Route path="/packagess" element={<Packagess />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/buypackage" element={<BuyPackageNow />} />


        </Routes>

       
      </BrowserRouter>
    </>
  );
}

export default App;
