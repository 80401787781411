import Nav from '../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import "./pack.css"


function Mainpage() {
  const [plan, setPlan] = useState([]);
  const [currentid, setCurrentId] = useState('');
  const [currentname, setCurrenName] = useState('');
  const [selectpack, setselectpack] = useState(1);
  const navigate = useNavigate();

  const changeProductList = (i, changes) => {

    const oldArr = [...plan];

    const oldOb = oldArr[0];
    oldArr[0] = { ...oldOb, ...changes };
    oldArr[0].purchase_price = Number(oldArr[0].price)*Number(oldArr[0].month)
    oldArr[0].uid =currentid;

    const oldOb1 = oldArr[1];
    oldArr[1] = { ...oldOb1, ...changes };
    oldArr[1].purchase_price = Number(oldArr[1].price)*Number(oldArr[1].month)
    oldArr[1].uid =currentid;

    const oldOb2 = oldArr[2];
    oldArr[2] = { ...oldOb2, ...changes };
    oldArr[2].purchase_price = Number(oldArr[2].price)*Number(oldArr[2].month)
    oldArr[2].uid =currentid;

    const oldOb3 = oldArr[3];
    oldArr[3] = { ...oldOb3, ...changes };
    oldArr[3].purchase_price = Number(oldArr[3].price)*Number(oldArr[3].month)
    oldArr[3].uid =currentid;


    console.log(oldArr[i]," issue ")
    setPlan(oldArr);
    
  };


  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('master'));
    if (v === null) {
      navigate('/login');
    } else {
      setCurrentId(v[0].id);
      
      setCurrenName(v[0].name);
      getplan(v[0].id);
    }
  }, []);

  const getplan = async () => {
    const data = new FormData();
    data.append('uid', "");

    axios({
      method: 'post',
      url: '/apis/subscription/package_plans.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('see subc', s.data);
        if (s.data[0].msg === 'pass') {


          setPlan(s.data);
          console.log('s.data', s.data);
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">

      


            <div className="container">
              <center>
              <h1 style={{marginTop:"20px",color:"#063970"}} >Packages Showcase</h1>
              
              </center>
              <hr  style={{marginBottom:"40px"}}  />

              {plan.length === 0 ? (
                      <></>
                    ) : (
<>
<div className="row">
            <div className="col-md-3 col-sm-6">
                <div className="pricingTable">
                    <div className="pricingTable-header">
                        <i className="fa fa-adjust"></i>
                       
                        <div className="price-value"> {new Intl.NumberFormat('en-pk').format( Number(plan[0].purchase_price))} <span style={{fontSize:"12px"}}>PKRs.</span> <span className="month">per month</span> </div>
                    </div>
                    <h3 className="heading">{plan[0].title}</h3>
                    <div className="pricing-content" >
                        <ul>
                            <li><b>Total Leads </b> <>{plan[0].lead}</></li>
                            <li><b>Product Showcase	</b> {plan[0].showcase} </li>
                            <li><b>Product Posting</b> <i className="ti-check"></i> </li>
                            <li><b>Inventory</b> {plan[0].inventory == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>

                            <li><b>Credible Badge</b> {plan[0].badge ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>

                           

                            <li><b>Office Managment</b>  {plan[0].office_managment == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>

                            <li><b>Director</b>   {plan[0].dirctor == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )} </li>

                            <li ><b>Product Priority</b> {plan[0].exposure == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>

                            <li ><b>Performance Reports</b> {plan[0].performancer == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>
                            <li ><b>Relationship Manager</b>  <i className="ti-check"></i> </li>
                            <li ><b>Professional Website </b>  <i className="ti-check"></i> </li>

                            <li ><b>Analytics & Insights</b> {plan[0].analytics == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>



                           
                            <li>
                          <center>
                          <select value={selectpack} onChange={(v)=>{
                            setselectpack(v.target.value)
                            changeProductList(0,{"month":Number(v.target.value)})

                        }} style={{width:"50%",marginTop:"15px",textAlign:"center"}}  className="form-select"  aria-label="Default select example">
                                <option value={1}>1 Month</option>
                                <option value={3} >3 Month</option>
                                <option value={6} >6 Month</option>
                                <option value={12}>1 Year</option>
                              </select>
                          </center>
                            </li>
                        </ul>
                    </div>
                    <div onClick={() => {
                                      localStorage.setItem(
                                        'package_select',"0",
                                      );
                                      localStorage.setItem(
                                        'package_plan',
                                        JSON.stringify(plan),
                                      );
                                      navigate('/buypackage');
                                    }} style={{cursor:"pointer"}} className="pricingTable-signup">
                        <a  style={{color:"#fff"}}>Buy</a>
                    </div>
                </div>
            </div>

            <div className="col-md-3 col-sm-6">
                <div className="pricingTable green">
                    <div className="pricingTable-header">
                        <i className="fa fa-briefcase"></i>
                        <div className="price-value"> {new Intl.NumberFormat('en-pk').format( Number(plan[1].purchase_price))} <span style={{fontSize:"12px"}}>PKRs.</span> <span className="month">per month</span> </div>
                    </div>
                    <h3 className="heading">{plan[1].title}</h3>
                    <div className="pricing-content" >
                        <ul>
                            <li><b>Total Leads </b> <>{plan[1].lead}</></li>
                            <li><b>Product Showcase	</b> {plan[1].showcase} </li>
                            <li><b>Product Posting</b> <i className="ti-check"></i> </li>
                            <li><b>Inventory</b> {plan[1].inventory == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>

                            <li><b>Credible Badge</b> {plan[1].badge ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>

                           

                            <li><b>Office Managment</b>  {plan[1].office_managment == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>

                            <li><b>Director</b>   {plan[1].dirctor == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )} </li>

                            <li ><b>Product Priority</b> {plan[1].exposure == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>

                            <li ><b>Performance Reports</b> {plan[1].performancer == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>
                            <li ><b>Relationship Manager</b>  <i className="ti-check"></i> </li>
                            <li ><b>Professional Website </b>  <i className="ti-check"></i> </li>

                            <li ><b>Analytics & Insights</b> {plan[1].analytics == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>



                         
                            <li>
                          <center>
                          <select value={selectpack} onChange={(v)=>{
                            setselectpack(v.target.value)
                            changeProductList(1,{"month":Number(v.target.value)})
                          }} style={{width:"50%",marginTop:"15px",textAlign:"center"}}  className="form-select"  aria-label="Default select example">
                                <option value={1}>1 Month</option>
                                <option value={3} >3 Month</option>
                                <option value={6} >6 Month</option>
                                <option value={12}>1 Year</option>
                              </select>
                          </center>
                            </li>
                        </ul>
                    </div>
                    <div onClick={() => {
                                      localStorage.setItem(
                                        'package_select',"1",
                                      );
                                      localStorage.setItem(
                                        'package_plan',
                                        JSON.stringify(plan),
                                      );
                                      navigate('/buypackage');
                                    }} style={{cursor:"pointer"}} className="pricingTable-signup">
                        <a  style={{color:"#fff"}}>Buy</a>
                    </div>
                </div>
            </div>





            <div className="col-md-3 col-sm-6">
                <div className="pricingTable blue">
                    <div className="pricingTable-header">
                        <i className="fa fa-diamond"></i>
                        <div className="price-value"> {new Intl.NumberFormat('en-pk').format( Number(plan[2].purchase_price))} <span style={{fontSize:"12px"}}>PKRs.</span> <span className="month">per month</span> </div>
                    </div>
                    <h3 className="heading">{plan[2].title}</h3>
                    <div className="pricing-content" >
                        <ul>
                            <li><b>Total Leads </b> <>{plan[2].lead}</></li>
                            <li><b>Product Showcase	</b> {plan[2].showcase} </li>
                            <li><b>Product Posting</b> <i className="ti-check"></i> </li>
                            <li><b>Inventory</b> {plan[2].inventory == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>

                            <li><b>Credible Badge</b> {plan[2].badge ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>

                           

                            <li><b>Office Managment</b>  {plan[2].office_managment == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>

                            <li><b>Director</b>   {plan[2].dirctor == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )} </li>

                            <li ><b>Product Priority</b> {plan[2].exposure == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>

                            <li ><b>Performance Reports</b> {plan[2].performancer == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>
                            <li ><b>Relationship Manager</b>  <i className="ti-check"></i> </li>
                            <li ><b>Professional Website </b>  <i className="ti-check"></i> </li>

                            <li ><b>Analytics & Insights</b> {plan[2].analytics == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>



                        

                            <li>
                          <center>
                          <select value={selectpack} onChange={(v)=>{
                            setselectpack(v.target.value)
                            changeProductList(2,{"month":Number(v.target.value)})
                          }} style={{width:"50%",marginTop:"15px",textAlign:"center"}}  className="form-select"  aria-label="Default select example">
                                <option value={1}>1 Month</option>
                                <option value={3} >3 Month</option>
                                <option value={6} >6 Month</option>
                                <option value={12}>1 Year</option>
                              </select>
                          </center>
                            </li>
                        </ul>
                    </div>
                    <div onClick={() => {
                                      localStorage.setItem(
                                        'package_select',"2",
                                      );
                                      localStorage.setItem(
                                        'package_plan',
                                        JSON.stringify(plan),
                                      );
                                      navigate('/buypackage');
                                    }}  style={{cursor:"pointer"}} className="pricingTable-signup">
                        <a  style={{color:"#fff"}}>Buy</a>
                    </div>
                </div>
            </div>




            <div className="col-md-3 col-sm-6">
                <div className="pricingTable red">
                    <div className="pricingTable-header">
                        <i className="fa fa-cube"></i>
                        <div className="price-value"> {new Intl.NumberFormat('en-pk').format( Number(plan[3].purchase_price))} <span style={{fontSize:"12px"}}>PKRs.</span> <span className="month">per month</span> </div>
                    </div>
                    <h3 className="heading">{plan[3].title}</h3>
                    <div className="pricing-content" >
                        <ul>
                            <li><b>Total Leads </b> <>{plan[3].lead}</></li>
                            <li><b>Product Showcase	</b> {plan[3].showcase} </li>
                            <li><b>Product Posting</b> <i className="ti-check"></i> </li>
                            <li><b>Inventory</b> {plan[3].inventory == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>

                            <li><b>Credible Badge</b> {plan[3].badge ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>

                           

                            <li><b>Office Managment</b>  {plan[3].office_managment == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>

                            <li><b>Director</b>   {plan[3].dirctor == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )} </li>

                            <li ><b>Product Priority</b> {plan[3].exposure == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>

                            <li ><b>Performance Reports</b> {plan[3].performancer == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>
                            <li ><b>Relationship Manager</b>  <i className="ti-check"></i> </li>
                            <li ><b>Professional Website </b>  <i className="ti-check"></i> </li>

                            <li ><b>Analytics & Insights</b> {plan[3].analytics == 'true' ? (
                                    <i className="ti-check"></i>
                                  ) : (
                                    <i className="ti-close"></i>
                                  )}</li>



                         
                            <li>
                          <center>
                          <select value={selectpack} onChange={(v)=>{
                            setselectpack(v.target.value)
                            changeProductList(3,{"month":Number(v.target.value)})

                          }} style={{width:"50%",marginTop:"15px",textAlign:"center"}}  className="form-select"  aria-label="Default select example">
                                <option value={1}>1 Month</option>
                                <option value={3} >3 Month</option>
                                <option value={6} >6 Month</option>
                                <option value={12}>1 Year</option>
                              </select>
                          </center>
                            </li>
                        </ul>
                    </div>
                    <div    onClick={() => {
                                      localStorage.setItem(
                                        'package_select',"3",
                                      );
                                      localStorage.setItem(
                                        'package_plan',
                                        JSON.stringify(plan),
                                      );
                                      navigate('/buypackage');
                                    }}  style={{cursor:"pointer"}} className="pricingTable-signup">
                        <a  style={{color:"#fff"}}>Buy</a>
                    </div>
                </div>
            </div>
            
        </div>


</>
                    )}
       
    </div>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
