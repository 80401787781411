import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Nav from '../navbar';
function User() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [user, setUser] = useState([]);

  useEffect(() => {
    UserView();
    }, []);


  const UserView = async () => {
    const data = new FormData();
    data.append('', '');


    axios({
      method: 'post',
      url: '/apis/userlist.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('User List ', s.data);
        const a = s.data;
        setUser(a);
      })
      .catch(s => {
        console.log('here User List ', s);
      });
  };



  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                <div className="card-title">
                    <h3> User List</h3>
                  </div>
                  <hr/>

                {/* <div className="card-title">
                    <h4>Search Filters </h4>
                  </div>
                <div style={{flexDirection:"row"}} className='row '>

<div className='col-3'>

<select className='form-control' placeholder='Enter Categories Name' >

  <option>User Id</option>
  <option>Phone</option>

  </select> 
</div>
<div className='col-3'>

<input className='form-control' placeholder='Enter Categories Name' /> 
</div>
<div className='col-3'>

<button className='btn-primary my-1 px-3 py-1' > Search</button>
</div>

</div>
<hr/> */}
<br/>
                 
                 
                  <div className="card-body">
                    <div
                    //  style={{ height: "600px", overflowY: "auto" }}
                     
                     className="table-responsive">
                      <table  className="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>User Id</th>
                            <th>User Name</th>
                            <th>Company Name</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>Phone No.</th>
                          

                            <th>Status</th>
                            <th>Created at</th>
                            <th>Manage</th>

                          </tr>
                        </thead>
                        <tbody >
                          {user.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              {user.map((v, i) => (
                                <tr>
                                  <td>{i+1}</td>
                                  <td>{v.id}</td>
                                  <td>{v.name} </td>
                                  <td>{v.cname} </td>
                                  <td>{v.email} </td>
                                  <td>{v.add} </td>
                                  <td>{v.city} </td>
                                  <td>{v.phone} </td>
                                  <td>{v.status} </td>
                                 
                                  <td>{v.c} </td>
                                  <td><button onClick={()=>{
                                    navigate('/login')
                                  }} className='btn btn-primary'>Manage</button> </td>
                                 
                                
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default User;
