import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Nav from '../navbar';
function Buyleads() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [lead, setLead] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    ActivePromotion()

   
    if (v === null) {
    } else {
      setUid(v[0].id);
    }
  }, []);


  const ActivePromotion = async () => {
    const data = new FormData();
   
    data.append("val", "active");
   
    axios({
      method: "post",
      url: "/apis/promotions/manage_promotions.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Active Promotions",  s.data);
        setList(s.data)
        // navigate("/maincat");
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };



  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3> Active Promotion</h3>
                  </div>
                  <hr/>

            <br/>
                
                  <div className="card-body">
                    <div className="table-responsive">
                    <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>User Id</th>
                            <th>Product Id </th>
                         

                            <th>Image</th>
                            <th>Price</th>
                            <th>Promotion Price</th>
                            <th>Product Qty</th>
                          
                            <th>Start Date</th>
                            <th>End At</th>
                            <th>Status</th>
                            
                          

                          </tr>
                        </thead>
                        <tbody>
                          {list.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              {list.map((v, i) => (
                                <tr>
                                  <td>{i+1}</td>
                                  <td>{v.uid}</td>
                                  <td>{v.pid}</td>
                                  <td style={{width: '20%'}}>
                                    <img
                                      style={{width: "20%", height: "25%", width:"120px"}}
                                      src={v.img}
                                      alt={"No image Found"}
                                    />
                                  </td>
                                  <td>{v.price}</td>
                                  <td>{v.pro_price} </td>
                                  <td>{v.qty}</td>
                                
                                  <td>{v.start}</td>
                                  <td>{v.end}</td>
                                  <td style={{color:"green"}}>
                                    {v.pro_type=="yes"?'active':'inactive'}</td>
                             
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>



          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
