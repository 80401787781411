import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Nav from '../navbar';
function Buyleads() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [lead, setLead] = useState([]);
//   const [sold, setSold] = useState([]);
  const [users, setUsers] = useState([]);
  const [to, setTo] = useState('');
  const [from, setFrom] = useState('');

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    SignupReport();
    if (v === null) {
    } else {
      setUid(v[0].id);
    }
  }, []);




  
  const SignupReport = async () => {
    const data = new FormData();
    data.append('val',"view" );

    axios({
      method: 'post',
      url: '/apis/users/manage_users.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('User list ', s.data);
        const a = s.data;
        setUsers(a);
      })
      .catch(s => {
        console.log('here lead_view_all ', s);
      });
  };

  const Specific_Date = async (f,t) => {
    
    const data = new FormData();
     data.append("val", "dates")
     data.append("to", t)
     data.append("from", f)

    axios({
      method: "post",
      url: "/apis/users/manage_users.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Users list ", s.data);
        const a = s.data;
        setUsers(a);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              <div className="card">
                  <div   className="card-body">
                  <div className="card-title text-center">
                    <h2>Sign Up Reports </h2>
                  </div>
                  <div className="text-right">
                  <div >
                  <label className="mx-2" >From:  </label>
                  <input type='date' value={to} onChange={(v)=>{
                    setTo(v.target.value)
                  }}/>
                  </div>
                  <div >
                  <label className="mx-2" >To:  </label>
                  <input type='date' value={from} onChange={(v)=>{
                    setFrom(v.target.value)
                  }}/>
                  </div>
                  <button onClick={()=>{
                    if(to!="" && from!= ""){
                      Specific_Date(to,from);
                    }
                     
                  }} className="btn-success">Search</button>
                      <div className="col-lg-12">
                <div className="card">
                  <div className="text-left card-title">
                    <h3> Signup Report</h3>
                  </div>
                  <hr/>
                  {/* <div className="card-title">
                    <h4>Search Filters </h4>
                  </div>
                <div style={{flexDirection:"row"}} className='row '>


                    <div className='col-3'>

                    <input className='form-control' type='date' /> 
                    </div>
                    <div className='col-3'>

                    <input className='form-control' type='date' /> 
                    </div>
                    <div className='col-3'>

                    <button className='btn-primary my-1 px-3 py-1' > Search</button>
                    </div>

                </div>
                <hr/> */}
                
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>User Id</th>
                            <th>User Name</th>
                            <th>Company Name</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>Phone No.</th>
                          

                            <th>Status</th>
                            <th>Created at</th>
                            <th>Manage</th>

                          </tr>
                        </thead>
                        <tbody>
                          {users.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              {users.map((v, i) => (
                                 <tr>
                                 <td>{i+1}</td>
                                 <td>{v.id}</td>
                                 <td>{v.name} </td>
                                 <td>{v.cname} </td>
                                 <td>{v.email} </td>
                                 <td>{v.add} </td>
                                 <td>{v.city} </td>
                                 <td>{v.phone} </td>
                                 <td>{v.status} </td>
                                
                                 <td>{v.c} </td>
                                 <td><button onClick={()=>{
                                   navigate('/login')
                                 }} className='btn btn-primary'>Manage User</button> </td>
                                
                               
                               </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
                  </div>
                
                
                  </div>
                </div>
              {/* <!-- /# column --> */}
          
            </section>
            <br/>

          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
