import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Nav from '../navbar';
function User() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [user, setUser] = useState([]);
  const [active, setActive] = useState([]);
  const [pending, setPending] = useState([]);

  useEffect(() => {

    
    Active_Sheet();
    Pending_Sheet()  
    Change_Status()  
  }, []);

  const Change_Status = async (id,status) => {
    const data = new FormData();
    data.append('id', id);
    data.append('status', status);
    data.append('val', 'update');


    axios({
      method: 'post',
      url: '/apis/productlist/manage_products.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('Active Mattersheet List ', s.data);
        const a = s.data;
        Active_Sheet();
        Pending_Sheet();
      })
      .catch(s => {
        console.log('here User List ', s);
      });
  };


  const Active_Sheet = async () => {
    const data = new FormData();
    data.append('val', 'active');


    axios({
      method: 'post',
      url: '/apis/productlist/manage_products.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('Active Mattersheet List ', s.data);
        const a = s.data;
        setActive(a);
      })
      .catch(s => {
        console.log('here User List ', s);
      });
  };


  const Pending_Sheet = async () => {
    const data = new FormData();
    data.append('val', 'pending');


    axios({
      method: 'post',
      url: '/apis/productlist/manage_products.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('Pending Mattersheet List ', s.data);
        const a = s.data;
        setPending(a);
      })
      .catch(s => {
        console.log('here User List ', s);
      });
  };



  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                <div className="card-title">
                    <h3>Active Mattersheet List</h3>
                  </div>
                  <hr/>
                {/* <div className="card-title">
                    <h4>Search Filters </h4>
                  </div>
                <div style={{flexDirection:"row"}} className='row '>

<div className='col-3'>

<select className='form-control' placeholder='Enter Categories Name' >
  <option>Company Name</option>
  <option>User Id</option>
  <option>Name</option>
  <option>Date</option>
  </select> 
</div>
<div className='col-3'>

<input className='form-control' placeholder='Enter Categories Name' /> 
</div>
<div className='col-3'>

<button className='btn-primary my-1 px-3 py-1' > Search</button>
</div>

</div>
<hr/> */}
<br/>
                 
                 
<div className="card-body">
                    <div
                    //  style={{ height: "600px", overflowY: "auto" }}
                     
                     className="table-responsive">
                      <table  className="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th> ID</th>
                            <th>User Id</th>
                            <th>User Name</th>
                            <th>Mattersheet File </th>
                            <th>Uploaded At</th>
                            <th>Status</th>
                            

                          </tr>
                        </thead>
                        <tbody >
                          {active.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              {active.map((v, i) => (
                                <tr>
                                  <td>{i+1}</td>
                                  <td>{v.id}</td>
                                  <td>{v.uid}</td>
                                  
                                  <td className='text-uppercase'>{v.name} </td>
                                  <td>{v.file} </td>
                                  <td>{v.time} </td>
                                  <td onClick={()=>{
                                    Change_Status(v.id,"pending");
                                  }} style={{color:"green", cursor:"pointer"}}>{v.status=="active"?'active':'pending'} </td>
                                 
                                  <td>{v.c} </td>
                                
                                
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                <div className="card-title">
                    <h3>Pending Mattersheet List</h3>
                  </div>
                  <hr/>
                {/* <div className="card-title">
                    <h4>Search Filters </h4>
                  </div>
                <div style={{flexDirection:"row"}} className='row '>

<div className='col-3'>

<select className='form-control' placeholder='Enter Categories Name' >
  <option>Company Name</option>
  <option>User Id</option>
  <option>Name</option>
  <option>Date</option>
  </select> 
</div>
<div className='col-3'>

<input className='form-control' placeholder='Enter Categories Name' /> 
</div>
<div className='col-3'>

<button className='btn-primary my-1 px-3 py-1' > Search</button>
</div>

</div>
<hr/> */}
<br/>
                 
                 
                  <div className="card-body">
                    <div
                    //  style={{ height: "600px", overflowY: "auto" }}
                     
                     className="table-responsive">
                      <table  className="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th> ID</th>
                            <th>User Id</th>
                            <th>User Name</th>
                            <th>Mattersheet File </th>
                            <th>Uploaded At</th>
                            <th>Status</th>
                            

                          </tr>
                        </thead>
                        <tbody >
                          {pending.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              {pending.map((v, i) => (
                                <tr>
                                  <td>{i+1}</td>
                                  <td>{v.id}</td>
                                  <td>{v.uid}</td>
                                  
                                  <td className='text-uppercase'>{v.name} </td>
                                  <td>{v.file} </td>
                                  <td>{v.time} </td>
                                  <td
                                   onClick={()=>{
                                    Change_Status(v.id,"active");
                                  }} 
                                  style={{color:"red", cursor:"pointer"}}>{v.status=="active"?'active':'pending'} </td>
                                 
                                  <td>{v.c} </td>
                                
                                
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default User;
