import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Nav from '../navbar';

function Driver() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [user, setUser] = useState([]);
  const [show, setShow] = useState('false');

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
 
    MainCat();
    
  }, []);


  const MainCat = async () => {
    const data = new FormData();
    data.append('', '');
    axios({
      method: 'post',
      url: '/apis/cat/allcat.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('Categories List ', s.data);
        const a = s.data;
        setUser(a);
      })
      .catch(s => {
        console.log('here User List ', s);
      });
  };

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                    
                {/* <div style={{flexDirection:"row"}} className='row '>

    <div className='col-3'>

<input className='form-control' placeholder='Enter Categories Name' /> 
    </div>
    <div className='col-3'>

    <button > Add New Category</button>
    </div>

</div> */}
<br/>
                  <div className="card-title">
                    <h4>Main Categories List</h4>
                  </div>
                 
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Image</th>
                            <th>Main Categories Name</th>
                            <th>Sub Category</th>
                            <th>Controls</th>

                           
                            {/* <th>Status</th> */}
                            
                          </tr>
                        </thead>
                        <tbody>
                          {user.length === 0 ? (
                            <>
                            <tr>
                            <td
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                  colSpan={10}>
                                No Category Found
                              </td>
                              </tr>
                              </>
                          ) : (
                            <>
                              {user.map((v, i) => (
                                <tr>
                                
                                  <td>{i+1}</td>
                                  <td style={{width: '20%'}}>
                                    <img
                                      style={{width: "20%", height: "25%", width:"120px"}}
                                      src={v.image_path}
                                      alt={"No image Found"}
                                    />
                                  </td>
                                  <td>{v.title}</td>
                                  <td>
                                    <a href={'/subcat?id='+v.id}>  <button className='btn-primary'>Sub Category</button></a></td>
                                 
                                  <td><i  
                                  onClick={() => {
                                    localStorage.setItem("catid", v.id)
                                    localStorage.setItem("title", v.title)
                                    navigate('/updatecat')
                                  }} 
                                  style={{color:"red"}} className='ti-pencil'> </i></td>
                                 
                                
                                </tr>
                               
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
              </div>
            </section>
          
          </div>
        </div>
      </div>
    </body>
  );
}
export default Driver;
