import Nav from "../navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  const [amount, setAmount] = useState();
  const [title, setTitle] = useState("");
  const [img, setImg] = useState('');
  const [cat, setCat] = useState("");


  const [uid, setUid] = useState("");

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    
    const catid = localStorage.getItem("catid");
    const tit = localStorage.getItem("title");
  setCat(catid)
  setTitle(tit)
 console.log(catid)
   
    if (v === null) {
    } else {
      setUid(v[0].id);
    }
  }, []);



  const Updatecategory = async (cid) => {
    const data = new FormData();
    data.append("id", cid);
    data.append("img", img);
    data.append("title", title);
    data.append("val", "update");
   
    axios({
      method: "post",
      url: "/apis/cat/managecat.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Updates",  s.data);
        navigate("/maincat");
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

 

  return (
    <>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3>Update Category</h3>
                  </div>

                  {/* Form Start */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-title">
                          <h5> </h5>
                        </div>

                        <div className="card-body">
                          <div className="basic-elements">
                            <div className="row">
                              <div className="col-6 form-group">
                                <label> Category Name</label>

<input
                    className="form-control"
                    type="text"
                    value={title}
                    onChange={v => {
                      setTitle(v.target.value);
                    }}
                  />
                              </div>
                              <div className="col-6 form-group">
                                <label>Category Image</label>
                                <input
                    className="form-control"
                
                    onChange={v => {
                      setImg(v.target.files[0]);
                    }}
                    type="file"
                  />
                              </div>
                            </div>
                            <center>
                              <div
                                onClick={() => {
                                    if(cat!=""){
                                        Updatecategory(cat)
                                    }else{
                                        alert("No Category Selected...")
                                    }
                                    
                                }}
                                className="btn btn-primary py-3 px-3 my-4"
                              >
                                Save
                              </div>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
