import Nav from "./navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";

function Mainpage() {
  const navigate = useNavigate();
  const [allviewMsg, setAllViewMsg] = useState([]);
  const [chat, setChat] = useState([]);
  const [chatid, setChatid] = useState("");
  const [uid, setUid] = useState("");
  const [lead, setLead] = useState([]);
  const [uiduser, setuiduser] = useState("");

  const [debit, setDebit] = useState('');
  const [datedebit, setDateDebit] = useState('');
  const [credit, setCredit] = useState('');
  const [creditdate, setCreditdate] = useState('');
  const [balance, setBalance] = useState('');
  const [date, setDate] = useState('');
  const [allcat, setAllcat] = useState('');
  const [maincat, setMaincat] = useState('');


  useEffect(() => {
   

    const v = JSON.parse(localStorage.getItem("master"));
   
    
    if (v === null) {
      navigate("/login");
    } else {

      Category()
      setUid(v[0].id);
      lead_view_all(v[0].id);
     
      const uiduser = localStorage.getItem("chatuserid");
      // console.log(uiduser);
      setuiduser(uiduser);
      
      
   
    }
  }, []);
 



  const Category = async () => {
    const data = new FormData();
    data.append("val", "viewcat");

   
    axios({
      method: "post",
      url: "/apis/cat/managecat.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Categories",  s.data);
        if(s.data[0].code== "pass")
        {
setAllcat(s.data[0].allcat)
setMaincat(s.data[0].maincat)
        }
        if(s.data[0].code== "fail")
        {
          alert(s.data[0].msg)
        }else{
        }
        // navigate("/maincat");
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const userNo = async () => {
    const data = new FormData();
    data.append("val", "viewcat");

   
    axios({
      method: "post",
      url: "/apis/userlist.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Categories",  s.data);
        if(s.data[0].code== "pass")
        {
setAllcat(s.data[0].allcat)
setMaincat(s.data[0].maincat)
        }
        if(s.data[0].code== "fail")
        {
          alert(s.data[0].msg)
        }else{
        }
        // navigate("/maincat");
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

 

  const checkvalidity = (lead_id) => {
    
    
    localStorage.setItem(
      'leadid',
      lead_id,
    );
    // navigate('/chat');
    navigate('/sendquote');
  }


  const lead_view_all = async (id) => {
    const data = new FormData();
    data.append("uid", id);

    axios({
      method: "post",
      url: "/pro/lead_view_all.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log("lead list ", s.data);
        const a = s.data;
        setLead(a);
      })
      .catch((s) => {
        // console.log("here lead_view_all ", s);
      });
  };

  const viewchat = async (id, i) => {
    const data = new FormData();
    data.append("one", id);
    data.append("two", i);

    axios({
      method: "post",
      url: "/pro/chatbox/chatbox.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log("chat list ", s.data);
        const a = s.data;
        if (a[0].code == "pass") {
          // setProductList(a);

          localStorage.setItem("ProductListInventory", JSON.stringify(s.data));
        }
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };



 
  

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3>Dashboard</h3>
                    <br />
               <h4>Master Admin</h4>   
               <br /> 
                    <div className="row">
            <div className="col-lg-3">
              <div className="card bg-info">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-layout-grid4-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">New Visitors on Homepage </div>
                      <div className="stat-text">{new Intl.NumberFormat('en-pk').format(balance)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{cursor:"pointer"}} className="col-lg-3">
              <div className="card bg-danger">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-menu"></i>
                  </div>
                  <div  
                  style={{cursor:"pointer"}}
                  onClick={()=>{
                    navigate("/maincat")
                  }} className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">No. of Main Categories</div>
                      <div className="stat-text">{new Intl.NumberFormat('en-pk').format(maincat)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{cursor:"pointer"}}
              onClick={()=>{
                navigate("/maincat")
              }}
            className="col-lg-3">
              <div className="card bg-dark">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-menu-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">No. of All Categories</div>
                      <div className="stat-text">{new Intl.NumberFormat('en-pk').format(allcat)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3">
            <Link to="/topup">
              <div className="card bg-success">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-help"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Quieries / Complaints</div>
                      <div className="stat-text">{new Intl.NumberFormat('en-pk').format(credit)}</div>
                    </div>
                  </div>
                </div>
              </div>
              </Link>
            </div>
           */}
          </div>
                    <div className="row">
            <div 
             style={{cursor:"pointer"}}
             onClick={()=>{
               navigate("/signupReport")
             }}
            className="col-lg-3">
              <div className="card bg-success">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-stats-up"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">No. of Signup Daily </div>
                      {/* <div className="stat-text">{new Intl.NumberFormat('en-pk').format(balance)}</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div 
              style={{cursor:"pointer"}}
              onClick={()=>{
                navigate("/userlist")
              }}
            className="col-lg-3">
              <div className="card bg-danger">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-user"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">No. of Users</div>
                      {/* <div className="stat-text">{new Intl.NumberFormat('en-pk').format(debit)}</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{cursor:"pointer"}} onClick={()=>{
              navigate('/packages')
            }} className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-stats-up text-white"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib text-white">
                      <div className="stat-heading">No. of Active subscription</div>
                      {/* <div className="stat-text">{new Intl.NumberFormat('en-pk').format(credit)}</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div 
             style={{cursor:"pointer"}} onClick={()=>{
              navigate('/promotion')
            }}
            className="col-lg-3">
           
              <div className="card bg-secondary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti ti-plus text-white"></i>
                  </div>
                  <div className="stat-content text-white">
                    <div className="text-left dib">
                      <div className="stat-heading">No. of Promotions Products Daily</div>
                      {/* <div className="stat-text">{new Intl.NumberFormat('en-pk').format(credit)}</div> */}
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          
          </div>
                    <div className="row">
            <div 
            style={{cursor:"pointer"}} onClick={()=>{
              navigate('/dailyquote')
            }}
            className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-quote-right"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">No. of Get Quotes Daily </div>
                      {/* <div className="stat-text">{new Intl.NumberFormat('en-pk').format(balance)}</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            <div style={{cursor:"pointer"}} onClick={()=>{
              navigate('/expired')
            }}  className="col-lg-3">
              <div className="card bg-secondary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-eye text-white "></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left text-white  dib">
                      <div className="stat-heading">No. of Expired subscription</div>
                      {/* <div className="stat-text">{new Intl.NumberFormat('en-pk').format(credit)}</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  style={{cursor:"pointer"}} onClick={()=>{
              navigate('/mattersheetlist')
            }} className="col-lg-3">
           
              <div className="card bg-dark">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti ti-plus"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Matter Sheet Details</div>
                      {/* <div className="stat-text"><br/></div> */}
                    </div>
                  </div>
                </div>
              </div>
       
            </div>
          
          </div>
          <br/>
          <br/>
                  </div>
                

                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
